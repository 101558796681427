import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {EmployeePivot} from '../../../../../core/models/employee-pivot';
import {SubAssignment} from '../../../../../core/models/sub-assignment';
import {Assignment} from '../../../../../core/models/assignment';
import {CrudService} from '../../../../../core/services/crud.service';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  public subAssignments: SubAssignment[] = [];

  @Input() employeeData?: EmployeePivot;

  constructor(
      private crudService: CrudService,
      private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    if (this.employeeData && this.employeeData.id) {
      this.crudService.fetchEntities<SubAssignment>('sub_assignment', {'filter[assignment_id]': this.employeeData.id, include: 'cost_center' }).subscribe(response => {
            this.subAssignments = response.data;
            this.cd.detectChanges();
          }
      );
    }
  }

}
