<div class="hrep-faq-container">
<div class="col-12 hrep-headline d-flex align-items-center">
    <i class="icon-question hrep-txt-primary me-2"></i>
    <h6 class="small fw-bold mb-0">Hilfe & Support</h6>
</div>
<hr class="hrep-hr">
<div class="col-10 offset-1">
    <h4 class="text-uppercase small fw-bolder mb-0 hrep-txt-primary">Wir sind für Sie da!</h4>
    <p>
        Um den Bewerbungsprozess für Sie und uns so effizient wie möglich zu
        gestalten, verwenden wir ein Bewerberportal. Dies bedeutet für Sie ein
        rascheres Feedback von uns und eine bessere Übersicht.</p>
    <div class="col-8 offset-2 info-box pt-3 pb-4">
        <div class="pb-3">
            Service Hotline: 05 / 100 100 – 90 <br>
            E-Mail: help@itknowation.com
        </div>
        <div>
            Erreichbarkeit: Montag bis Sonntag 07:30 - 18:00
        </div>
    </div>
</div>
<div class="hrep-accordion accordion-primary">
    <ejs-accordion expandMode='Single'>
        <e-accordionitems>
            <ng-container *ngFor="let question of faqQuestions; let i =index;">
                <e-accordionitem [expanded]=" i == 0 ? true : null">
                    <ng-template #header>
                        <span class="accordion-header-prefix">{{i + 1}}</span>
                        <div>{{question.title}}</div>
                    </ng-template>
                    <ng-template #content>
                        <div>{{question.short_content}}</div>
                    </ng-template>
                </e-accordionitem>
            </ng-container>
        </e-accordionitems>
    </ejs-accordion>
</div>
</div>