<div class="gray-container">
    <div class="container">
        <div class="row">
          <div class="d-block d-12 d-md-none p-0">
            <app-menu-mobile></app-menu-mobile>
          </div>
          <div class="d-none d-md-block col-md-12">
              <div class="row">
                  <div class="col-md-3 logo logo-small">
                  <a routerLink="dashboard"><img class="img-fluid" src="assets/layout/images/logo/itknowation.png"></a>
                </div>
              </div>
          </div>
          <div class="d-none d-md-block col-lg-3">
              <app-left-sidebar></app-left-sidebar>
          </div>
          <div class="col-12 col-lg-6">
              <router-outlet></router-outlet>
          </div>
          <div class="d-none d-md-block col-lg-3">
              <app-right-sidebar></app-right-sidebar>
          </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
