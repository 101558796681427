import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from '../../../../core/services/crud.service';
import {Faq} from '../../../../core/models/faq';
import {ContractOfEmployment} from '../../../../core/models/contract-of-employment';
import {Message} from '../../../../core/models/message';
import {Document} from '../../../../core/models/document';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {User} from '../../../../core/models/user';
import {MessagesService} from '../../../../core/services/messages.service';
import {Employee} from '../../../../core/models/employee';
import {Assignment} from '../../../../core/models/assignment';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchListComponent implements OnInit {
  public frequentlyAskedQuestions: Faq[] = [];
  public currentUser: User | null = null;
  public activeEmployees: Assignment[] = [];
  public formerEmployees: Assignment[] = [];
  public candidateEmployees: Assignment[] = [];
  public bookmarkEmployees: Assignment[] = [];
  public messages: Message[] = [];
  public documents: Document[] = [];
  public expendedMessage: string = '';
  public searchValue: string | null = '';

  constructor(private route: ActivatedRoute,
              private crudService: CrudService,
              private authService: AuthenticationService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private messageSelectionService: MessagesService
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.searchValue = routeParams.searchValue;
      this.authService.getLoggedUser().subscribe(user => {
        this.currentUser = user;
        this.onLoadSearchResults(this.searchValue);
        this.cd.detectChanges();
      });
    });
  }
  private onLoadSearchResults(searchParams: any) {
    this.fetchActiveEmployees(searchParams);
    this.fetchFormerEmployees(searchParams);
    this.fetchCandidateEmployees(searchParams);
    this.fetchBookmarkEmployees(searchParams);
    const paramsFAQs = this.buildParamsFAQs(searchParams);
    this.fetchFAQs(paramsFAQs);
    const paramsMessages = this.buildParamsMessages(searchParams);
    this.fetchMessages(paramsMessages);
    const paramsDocuments = this.buildParamsDocuments(searchParams);
    this.fetchDocuments(paramsDocuments);
  }
  private buildParamsFAQs(searchParams: string): { [key: string]: string } {
    return {
      'filter[title]': this.searchValue ? searchParams : ''
    };
  }

  private fetchFAQs(params: { [key: string]: string }) {
    this.crudService.fetchEntities<Faq>('frequently_asked_question', params).subscribe(response => {
        this.frequentlyAskedQuestions = response.data;
        this.cd.detectChanges();
      }
    );
  }

  private fetchActiveEmployees(searchParams: string) {
    const params = {
      'filter[active]': 'scope',
      'filter[assignments.customer_id]': this.currentUser?.customer?.id || 'x',
      'include': 'employee,customer,cost_center',
      'filter[employee.first_name]': this.searchValue ? searchParams : '',
    }
    this.crudService.fetchEntities<Employee>('assignment', params).subscribe(response => {
        this.activeEmployees = response.data;
        this.cd.detectChanges();
      }
    );
  }

  private fetchFormerEmployees(searchParams: string) {
    const params = {
      'filter[old]': 'scope',
      'filter[assignments.customer_id]': this.currentUser?.customer?.id || 'x',
      'include': 'employee,customer,cost_center',
      'filter[employee.first_name]': this.searchValue ? searchParams : '',
    }
    this.crudService.fetchEntities<Employee>('assignment', params).subscribe(response => {
        this.formerEmployees = response.data;
        this.cd.detectChanges();
      }
    );
  }

  private fetchCandidateEmployees(searchParams: string) {
    const params = {
      'filter[candidate]': 'scope',
      'filter[assignments.customer_id]': this.currentUser?.customer?.id || 'x',
      'include': 'employee,customer,cost_center',
      'filter[employee.first_name]': this.searchValue ? searchParams : '',
    }
    this.crudService.fetchEntities<Employee>('assignment', params).subscribe(response => {
        this.candidateEmployees = response.data;
        this.cd.detectChanges();
      }
    );
  }

  private fetchBookmarkEmployees(searchParams: string) {
    const params = {
      'filter[bookmark]': 'scope',
      'filter[assignments.customer_id]': this.currentUser?.customer?.id || 'x',
      'include': 'employee,customer,cost_center',
      'filter[employee.first_name]': this.searchValue ? searchParams : '',
    }
    this.crudService.fetchEntities<Employee>('assignment', params).subscribe(response => {
        this.bookmarkEmployees = response.data;
        this.cd.detectChanges();
      }
    );
  }

  private buildParamsMessages(searchParams: string): { [key: string]: string } {
    return {
      'filter[title]': this.searchValue ? searchParams : '',
      include: 'user',
    };
  }

  private fetchMessages(params: { [key: string]: string }) {
    this.crudService.fetchEntities<Message>('message', params).subscribe(response => {
          this.messages = response.data;
          this.cd.detectChanges();
        }
    );
  }

  private buildParamsDocuments(searchParams: string): { [key: string]: string } {
    return {
      'filter[name]': this.searchValue ? searchParams : ''
    };
  }

  private fetchDocuments(params: { [key: string]: string }) {
    this.crudService.fetchEntities<Document>('document', params).subscribe(response => {
          this.documents = response.data;
          this.cd.detectChanges();
        }
    );
  }

  public search(event: KeyboardEvent): void {
    this.router.navigate(['/search-list/' + (event.target as HTMLInputElement).value ]);
  }

  public onOpenMessage(message_id: string) {
    this.messageSelectionService.expandMessage(message_id);
    this.expendedMessage = message_id;
  }
}
