<div class="col-12 hrep-headline d-flex align-items-center">
    <i class="icon-envelope-open hrep-txt-primary me-2"></i>
    <h6 class="small fw-bold mb-0">Newsletter</h6>
</div>
<hr class="hrep-hr">
<div class="hrep-accordion accordion-primary accordion-with-list">
    <ejs-accordion expandMode='Multiple' *ngIf="unreadMessages.length || readMessages.length">
        <e-accordionitems>
            <e-accordionitem expanded="true">
                <ng-template #header>
                    <span class="accordion-header-prefix no-content"></span>
                    <div>aktuelle Mitteilungen</div>
                </ng-template>
                <ng-template #content>
                    <div class="hrep-list hrep-messages-list">
                        <ejs-listview
                                id="listview_template_unread"
                                cssClass="e-list-template"
                                [dataSource]="unreadMessages"
                        >
                            <ng-template #template let-listItem="">
                                <div class="e-list-wrapper e-list-multi-line">
                                    <app-newsletter-item [listItem]="listItem" (click)="onOpenMessage(listItem.id)" [isExpanded]="expendedNewsletter === listItem.id"></app-newsletter-item>
                                </div>
                                <div class="hrep-divider"></div>
                            </ng-template>
                        </ejs-listview>
                    </div>
                </ng-template>
            </e-accordionitem>
            <e-accordionitem expanded="true">
                <ng-template #header>
                    <span class="accordion-header-prefix no-content"></span>
                    <div>gelesene Mitteilungen</div>
                </ng-template>
                <ng-template #content>
                    <div class="hrep-list hrep-messages-list">
                        <ejs-listview
                                id="listview_template_read"
                                cssClass="e-list-template"
                                [dataSource]="readMessages"
                        >
                            <ng-template #template let-listItem="">
                                <div class="e-list-wrapper e-list-multi-line">
                                    <app-newsletter-item [listItem]="listItem" (click)="onOpenMessage(listItem.id)" [isExpanded]="expendedNewsletter === listItem.id"></app-newsletter-item>
                                </div>
                                <div class="hrep-divider"></div>
                            </ng-template>
                        </ejs-listview>
                    </div>
                </ng-template>
            </e-accordionitem>
        </e-accordionitems>
    </ejs-accordion>
</div>
