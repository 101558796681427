<div class='content'>
    <div class="container">
        <div class="row">
            <div>
                <form [formGroup]="form" (ngSubmit)="doSubmit()">
                    <div class="hrep-accordion accordion-light">
                        <ejs-accordion>
                            <e-accordionitems>
                                <e-accordionitem expanded='true'>
                                    <ng-template #header>
                                        <div>{{ 'PROFILE.PERSONAL_DATA' | translate }}</div>
                                    </ng-template>
                                    <ng-template #content>
                                        <div class="row">
                                            <div class="col-6 d-flex justify-content-between align-items-center mb-2">
                                                <label for="name">{{ 'PROFILE.FIRST_NAME' | translate }}</label>
                                                <ejs-textbox id="name" [cssClass]="(form.get('first_name')?.errors ? 'e-error' : '') + ' hrep-input'" type="text" formControlName="first_name"></ejs-textbox>
                                            </div>
                                            <div class="col-6 d-flex justify-content-between align-items-center mb-2">
                                                <label for="surname">{{ 'PROFILE.LAST_NAME' | translate }}</label>
                                                <ejs-textbox id="surname" [cssClass]="(form.get('last_name')?.errors ? 'e-error' : '') + ' hrep-input'" type="text" formControlName="last_name"></ejs-textbox>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-8 d-flex justify-content-between align-items-center mb-2">
                                                <label>{{ 'PROFILE.GENDER' | translate }}</label>
                                                <ejs-radiobutton label="{{ 'PROFILE.FEMALE' | translate }}" formControlName="sex" value="w"></ejs-radiobutton>
                                                <ejs-radiobutton label="{{ 'PROFILE.MALE' | translate }}" formControlName="sex" value="m"></ejs-radiobutton>
                                                <ejs-radiobutton label="{{ 'PROFILE.OTHER' | translate }}" formControlName="sex" value=""></ejs-radiobutton>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-10 d-flex justify-content-between align-items-center mb-2">
                                                <label>{{ 'PROFILE.BIRTHDAY' | translate }}:</label>
                                                <div class="col-2">
                                                    <ejs-dropdownlist id='dday' [dataSource]='days' cssClass="hrep-dropdown"
                                                                      formControlName="birth_date_day"></ejs-dropdownlist>
                                                </div>
                                                <div class="col-2">
                                                    <ejs-dropdownlist id='dmonth' [dataSource]='months' cssClass="hrep-dropdown"
                                                                      formControlName="birth_date_month"></ejs-dropdownlist>
                                                </div>
                                                <div class="col-3">
                                                    <ejs-dropdownlist id='dyear' [dataSource]='years' cssClass="hrep-dropdown"
                                                                      formControlName="birth_date_year"></ejs-dropdownlist>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </e-accordionitem>
                                <e-accordionitem expanded='true'>
                                    <ng-template #header>
                                        <div>{{ 'PROFILE.ADDRESS' | translate }}</div>
                                    </ng-template>
                                    <ng-template #content>
                                        <div class="row">
                                            <div class="col-5 d-flex justify-content-between align-items-center mb-2">
                                                <label>{{ 'PROFILE.STREET' | translate }}</label>
                                                <div class='textboxes'>
                                                    <ejs-textbox placeholder="{{ 'PROFILE.STREET' | translate }}" [cssClass]="(form.get('street')?.errors ? 'e-error' : '') + ' hrep-input'" formControlName="street"></ejs-textbox>
                                                </div>
                                            </div>
                                            <div class="col-4 d-flex justify-content-between align-items-center mb-2">
                                                <label>{{ 'PROFILE.HOUSE_NO' | translate }}</label>
                                                <ejs-textbox
                                                        width="50"
                                                        [cssClass]="(form.get('house_number')?.errors ? 'e-error' : '') + ' hrep-input'"
                                                        formControlName="house_number"
                                                >
                                                </ejs-textbox>
                                            </div>
                                            <div class="col-2 d-flex justify-content-between align-items-center mb-2">
                                                <label>{{ 'PROFILE.DOOR_NUMBER' | translate }}</label>
                                                <div class='textboxes'>
                                                    <ejs-textbox cssClass="hrep-input" width="50" formControlName="door_number"></ejs-textbox>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-5 d-flex align-items-center justify-content-between mb-2">
                                                <label>{{ 'PROFILE.POSTAL_CODE' | translate }}</label>
                                                <ejs-textbox
                                                        [cssClass]="(form.get('postal_code')?.errors ? 'e-error' : '') + ' hrep-input'"
                                                        formControlName="postal_code"
                                                >
                                                </ejs-textbox>
                                            </div>
                                            <div class="col-5 d-flex align-items-center justify-content-between mb-2">
                                                <label>{{ 'PROFILE.TOWN' | translate }}</label>
                                                <ejs-textbox id="town" [cssClass]="(form.get('town')?.errors ? 'e-error' : '') + ' hrep-input'" type="text" formControlName="town"></ejs-textbox>
                                            </div>
                                        </div>
                                    </ng-template>
                                </e-accordionitem>

                                <e-accordionitem expanded="true">
                                    <ng-template #header>
                                        <div>{{ 'PROFILE.CONTACT_DETAILS' | translate }}</div>
                                    </ng-template>
                                    <ng-template #content>
                                        <div class="col-6 d-flex align-items-center justify-content-between mb-2">
                                            <label>{{ 'PROFILE.MOBILE' | translate }}</label>
                                            <div class='textboxes'>
                                                <ejs-textbox type="tel" [cssClass]="(form.get('phone_number')?.errors ? 'e-error' : '') + ' hrep-input'" formControlName="phone_number"></ejs-textbox>
                                            </div>
                                            <span class="icon-check hrep-txt-primary"></span>
                                        </div>
                                        <div class="col-6 d-flex align-items-center justify-content-between mb-2">
                                            <label>{{ 'COMMON.EMAIL' | translate }}</label>
                                            <div class='textboxes'>
                                                <ejs-textbox type="email" [cssClass]="(form.get('email')?.errors ? 'e-error' : '') + ' hrep-input'" formControlName="email"></ejs-textbox>
                                            </div>
                                            <span class="icon-check hrep-txt-primary"></span>
                                        </div>
                                    </ng-template>
                                </e-accordionitem>

                            </e-accordionitems>
                        </ejs-accordion>

                        <div class="form-field text-center">
                            <button [disabled]="submitInProgress" cssClass="e-small" ejs-button type="submit" class="e-btn e-primary">{{ 'COMMON.SAVE' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

