import {Component, Input, OnInit} from '@angular/core';
import {EmployeePivot} from '../../../../../core/models/employee-pivot';
import {CrudService} from '../../../../../core/services/crud.service';
import {Assignment} from '../../../../../core/models/assignment';

@Component({
  selector: 'app-candidate-employee-details',
  templateUrl: './candidate-employees.component.html',
  styleUrls: ['./candidate-employees.component.scss'],
})
export class CandidateEmployeesComponent implements OnInit {

  @Input() employeeData?: EmployeePivot;

  constructor(
    private crudService: CrudService,
  ) { }

  ngOnInit(): void {

  }

  bookmark(): void {
    if (this.employeeData && this.employeeData.id) {
      this.crudService.createEntity<Assignment>('bookmark_employee/bookmark', {'assignment_id': this.employeeData.id}).subscribe(response => {

      });
    }
  }

  unBookmark(): void {
    if (this.employeeData && this.employeeData.id) {
      this.crudService.createEntity<Assignment>('bookmark_employee/unbookmark', {'assignment_id': this.employeeData.id}).subscribe(response => {

      });
    }
  }
}
