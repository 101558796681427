import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../../core/models/user';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {CrudService} from '../../../../core/services/crud.service';
import {ToastService} from '../../../../core/services/toast.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftSidebarComponent implements OnInit {
  public form: FormGroup;
  public link: {route: string} = {
    route: '/dashboard'
  };
  public current_user: User | null = null;

  constructor(
      private fb: FormBuilder,
      private authService: AuthenticationService,
      private cd: ChangeDetectorRef,
      private crudService: CrudService,
      private toastService: ToastService
  ) {
    this.form = this.fb.group({
      text: this.fb.control('')
    });
  }

  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      this.current_user = user;
      this.cd.detectChanges();
    });
  }

  public sendEmail(): void {
    if (this.form.value.text.length === 0) {
      return;
    }
    this.crudService.createEntity('memo', {
        text: this.form.value.text,
        user: this.current_user
    }).subscribe( result => {
      this.toastService.show({
        title: 'Success',
        content: 'Message Sent',
      });
      this.form.get('text')?.patchValue('');
    });
  }
}
