import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/common/login/login.component';
import {LogoutComponent} from './components/common/logout/logout.component';
import {BaseComponent} from './components/common/base/base.component';
import {ResetPasswordComponent} from './components/common/reset-password/reset-password.component';
import {ProfileComponent} from './components/common/base/profile/profile.component';
import {FAQComponent} from './components/common/base/faq/faq.component';
import {MessagesComponent} from './components/common/base/messages/messages.component';
import {DocumentsComponent} from './components/common/base/documents/documents.component';
import {SearchListComponent} from './components/common/base/search-list/search-list.component';
import {DashboardComponent} from './components/common/base/dashboard/dashboard.component';
import {VacationOverviewComponent} from './components/common/base/vacation-overview/vacation-overview.component';
import {ContractEmploymentsComponent} from './components/common/base/contract-employments/contract-employments.component';
import {TimeTicketsUploadComponent} from './components/common/base/time-tickets-upload/time-tickets-upload.component';
import {DocumentsUploadComponent} from './components/common/base/documents-upload/documents-upload.component';
import {
  AuthGuardService as AuthGuard
} from './core/services/auth-guard.service';
import {DocumentTypesComponent} from './components/common/base/document-types/document-types.component';
import {LeftSidebarComponent} from './components/common/base/left-sidebar/left-sidebar.component';
import {AdministrationComponent} from "./components/common/base/administration/administration.component";
import {EmployeesComponent} from "./components/common/base/employees/employees.component";
import {NewsletterComponent} from './components/common/base/newsletter/newsletter.component';
import {
  AssignmentStatisticComponent
} from './components/common/base/assignment-statistic/assignment-statistic.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'faq',
        component: FAQComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'messages',
        component: MessagesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'documents/:documentType',
        component: DocumentsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'newsletter',
        component: NewsletterComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'document-types',
        component: DocumentTypesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'search-list/:searchValue',
        component: SearchListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vacations',
        component: VacationOverviewComponent
      },
      {
        path: 'contracts',
        component: ContractEmploymentsComponent
      },
      {
        path: 'statistic',
        component: AssignmentStatisticComponent
      },
      {
        path: 'tickets',
        component: TimeTicketsUploadComponent
      },
      {
        path: 'documents-upload',
        component: DocumentsUploadComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'supervisor',
        component: LeftSidebarComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'administration',
        component: AdministrationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'employees/active',
        component: EmployeesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'employees/bookmark',
        component: EmployeesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'employees/former',
        component: EmployeesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'employees/candidate',
        component: EmployeesComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'password/reset/:token',
    component: ResetPasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
