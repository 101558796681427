import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {Router} from '@angular/router';
import {EmployeePivot} from '../../../../core/models/employee-pivot';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {EmployeeDetailsComponent} from './employee-details/employee-details.component';
import {EmitType} from '@syncfusion/ej2-base';
import {Assignment} from '../../../../core/models/assignment';
import {CandidateEmployeesComponent} from './candidate-employee-details/candidate-employees.component';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeesComponent implements OnInit {
  public employees: EmployeePivot[] = [];
  public fields: any = {text: 'name', value: 'name'};
  public genders: { [key: string]: any }[] = [
    {name: 'male'},
    {name: 'female'},
    {name: 'other'}
  ];
  public type = '';

  public targetElement: HTMLElement | undefined;
  public employee: EmployeePivot | undefined;
  public isOpened = false;
  @ViewChild('ejDialog') ejDialog: DialogComponent | undefined;
  @ViewChild(EmployeeDetailsComponent) employeeDetail: EmployeeDetailsComponent | undefined;
  @ViewChild(CandidateEmployeesComponent) candidateEmployeeDetail: CandidateEmployeesComponent | undefined;
  @ViewChild('container', { read: ElementRef }) container: ElementRef | undefined;

  constructor(
      private crudService: CrudService,
      private cd: ChangeDetectorRef,
      private authService: AuthenticationService,
      private router: Router
  ) {
  }

  ngOnInit(): void {

    const splits = this.router.url.split('/');
    this.type = splits[splits.length - 1];

    this.authService.getLoggedUser().subscribe(user => {
      const params: any = {
        'filter[assignments.customer_id]': user.customer?.id || 'x',
        include:'employee,customer,cost_center',
        'sort': '-valid_from',
      };
      switch (this.type) {
        case 'active':
          params['filter[active]']= 'scope';
          break;
        case 'former':
          params['filter[old]']= 'scope';
          break;
        case 'candidate':
          params['filter[candidate]']= 'scope';
          break;
        case 'bookmark':
          params['filter[bookmark]']= 'scope';
          break;
      }
      this.crudService.fetchEntities<Assignment>('assignment', params).subscribe(response => {
            this.employees = response.data;
            this.cd.detectChanges();
          }
      );
    });
    this.initializeTarget();
  }

  public initializeTarget: EmitType<any> = () => {
    this.targetElement = this.container?.nativeElement.parentElement;
  };

  public onOpenDialog = (data: any): void => {
    this.employee = data;
    this.isOpened = true;
    this.ejDialog?.show();
  };

  public onOpenAssignmentDialog = (data: any): void => {
    console.log(data);
  };

  public beforeOpen(event: any) {
    this.isOpened = true;
  }

  public beforeClose(event: any) {
    this.isOpened = false;
  }

}
