import { Injectable } from '@angular/core';
import {User} from '../models/user';
import {Observable, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorageService} from './local-storage.service';
import {environment} from '../../../environments/environment';
import {map, shareReplay} from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private static readonly LOCAL_STORAGE_TOKEN_KEY = 'token';
  private static readonly LOCAL_STORAGE_USER_KEY = 'user';

  public loggedOut: Subject<any> = new Subject<any>();

  public constructor(
    private router: Router,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
  }

  public login(email: string, password: string): Observable<Record<string, any>> {

    const headers = new HttpHeaders({
      accept: 'application/json'
    });

    return this.http.post(
      environment.ENTITY_MANAGER_URL_PREFIX + 'login_check',
      {
        email,
        password
      },
      {
        headers
      }
    ).pipe(
      map((response: Record<string, any>) => {
        this.setToken(response.token);
        this.setUser(response.user_id);
        return response;
      })
    );
  }

  public logout(): void {
    this.removeToken();

    this.loggedOut.next();
  }

  public setToken(token: string): void {
    this.localStorage.setItem(AuthenticationService.LOCAL_STORAGE_TOKEN_KEY, token);
  }

  public setUser(user_id: string): void {
    this.localStorage.setItem(AuthenticationService.LOCAL_STORAGE_USER_KEY, user_id);
  }

  public isLoggedIn(): boolean {
    return this.localStorage.itemExists(AuthenticationService.LOCAL_STORAGE_TOKEN_KEY);
  }

  public getLoggedUser(): Observable<User> {
    return this.http.get<User>(environment.ENTITY_MANAGER_URL_PREFIX + 'user/me', {params: {include:'supervisor,branchOffice,customer'}}).pipe(
      shareReplay(1)
    );
  }

  public getToken(): string {
    return 'Bearer ' + this.localStorage.getItem(AuthenticationService.LOCAL_STORAGE_TOKEN_KEY);
  }

  public getUser(): string {
    return this.localStorage.getItem(AuthenticationService.LOCAL_STORAGE_USER_KEY);
  }

  public resetPasswordRequest(params: any): Observable<any> {
    return this.http.post(environment.ENTITY_MANAGER_URL_PREFIX + 'reset_password_request', params);
  }

  public resetPassword(record: Record<string, unknown>, token: string | null | undefined, email: string | null | undefined): Observable<any> {
    record.token = token;
    record.email = email;
    return this.http.post(environment.ENTITY_MANAGER_URL_PREFIX + 'reset_password', record);
  }

  public changePassword(record: Record<string, unknown>): Observable<any> {
    return this.http.post(environment.ENTITY_MANAGER_URL_PREFIX + 'user/change_password', record);
  }

  private removeToken(): void {
    this.localStorage.removeItem(AuthenticationService.LOCAL_STORAGE_TOKEN_KEY);
    this.localStorage.removeItem(AuthenticationService.LOCAL_STORAGE_USER_KEY);
  }
}
