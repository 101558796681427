<div class="col-12 hrep-headline d-flex align-items-center">
    <i class="icon-magnifier hrep-txt-primary me-2"></i>
    <h6 class="small fw-bold mb-0">Suche</h6>
</div>
<hr class="hrep-hr">
<div id="search-inputs" class="col-10 offset-1">
    <div class="d-flex align-items-center">
        <ejs-textbox cssClass="hrep-input" [(value)]='searchValue' (keydown.enter)="search($event)"></ejs-textbox>
<!--        <ejs-chiplist id="chip" enableDelete="true">-->
<!--            <e-chips>-->
<!--                <e-chip text="Lohnzettel"></e-chip>-->
<!--                <e-chip text="Begriff 2"></e-chip>-->
<!--            </e-chips>-->
<!--        </ejs-chiplist>-->
    </div>
    <p class="small hrep-results-number">Ihre Suche ergab {{activeEmployees.length + formerEmployees.length + candidateEmployees.length + documents.length + frequentlyAskedQuestions.length + messages.length}} Treffer.</p>
</div>
<div id="search-results">
    <div class="hrep-accordion accordion-primary accordion-with-list">
        <ejs-accordion expandMode='Multiple' >
            <e-accordionitems>
                <e-accordionitem expanded="true" *ngIf="activeEmployees.length">
                    <ng-template #header>
                        <span class="accordion-header-prefix"><i class="icon-doc"></i></span>
                        <div>{{ ('CUSTOMER.ACTIVE_EMPLOYEES') | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="hrep-list hrep-search-list">
                            <ejs-listview
                                    id="listview_template_active_employee"
                                    cssClass="e-list-template"
                                    [dataSource]="activeEmployees"
                            >
                                <ng-template #template let-listItem="">
                                    <div class="e-list-wrapper e-list-multi-line">
                                        <div class="left-section icon-section">
                                            <span>
                                                <img src="../../../../../assets/layout/images/pdf-icon.png" alt=""/>
                                            </span>
                                        </div>
                                        <div class="main-section">
                                            <span class="e-list-item-header hrep-txt-primary">{{listItem.employee.first_name}} {{listItem.employee.last_name}}</span>
                                            <span class="e-list-content">{{listItem.valid_from | date}}</span>
                                        </div>
                                        <div class="right-section">
                                            <button ejs-button cssClass="hrep-icon-btn"><img
                                                    src="../../../../../assets/layout/images/pdf-download-icon.png" alt=""/>
                                            </button>
                                            <button ejs-button cssClass="hrep-icon-btn"
                                                    iconCss="icon-cloud-download"></button>
                                        </div>
                                    </div>
                                    <div class="hrep-divider"></div>
                                </ng-template>
                            </ejs-listview>
                        </div>
                    </ng-template>
                </e-accordionitem>
                <e-accordionitem expanded="true" *ngIf="formerEmployees.length">
                    <ng-template #header>
                        <span class="accordion-header-prefix"><i class="icon-doc"></i></span>
                        <div>{{ ('CUSTOMER.FORMER_EMPLOYEES') | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="hrep-list hrep-search-list">
                            <ejs-listview
                                    id="listview_template_former_employee"
                                    cssClass="e-list-template"
                                    [dataSource]="formerEmployees"
                            >
                                <ng-template #template let-listItem="">
                                    <div class="e-list-wrapper e-list-multi-line">
                                        <div class="left-section icon-section">
                                            <span>
                                                <img src="../../../../../assets/layout/images/pdf-icon.png" alt=""/>
                                            </span>
                                        </div>
                                        <div class="main-section">
                                            <span class="e-list-item-header hrep-txt-primary">{{listItem.employee.first_name}} {{listItem.employee.last_name}}</span>
                                            <span class="e-list-content">{{listItem.valid_from | date}}</span>
                                        </div>
                                        <div class="right-section">
                                            <button ejs-button cssClass="hrep-icon-btn"><img
                                                    src="../../../../../assets/layout/images/pdf-download-icon.png" alt=""/>
                                            </button>
                                            <button ejs-button cssClass="hrep-icon-btn"
                                                    iconCss="icon-cloud-download"></button>
                                        </div>
                                    </div>
                                    <div class="hrep-divider"></div>
                                </ng-template>
                            </ejs-listview>
                        </div>
                    </ng-template>
                </e-accordionitem>
                <e-accordionitem expanded="true" *ngIf="candidateEmployees.length">
                    <ng-template #header>
                        <span class="accordion-header-prefix"><i class="icon-doc"></i></span>
                        <div>{{ ('CUSTOMER.CANDIDATE_EMPLOYEES') | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="hrep-list hrep-search-list">
                            <ejs-listview
                                    id="listview_template_candidate_employee"
                                    cssClass="e-list-template"
                                    [dataSource]="candidateEmployees"
                            >
                                <ng-template #template let-listItem="">
                                    <div class="e-list-wrapper e-list-multi-line">
                                        <div class="left-section icon-section">
                                            <span>
                                                <img src="../../../../../assets/layout/images/pdf-icon.png" alt=""/>
                                            </span>
                                        </div>
                                        <div class="main-section">
                                            <span class="e-list-item-header hrep-txt-primary">{{listItem.employee.first_name}} {{listItem.employee.last_name}}</span>
                                            <span class="e-list-content">{{listItem.valid_from | date}}</span>
                                        </div>
                                        <div class="right-section">
                                            <button ejs-button cssClass="hrep-icon-btn"><img
                                                    src="../../../../../assets/layout/images/pdf-download-icon.png" alt=""/>
                                            </button>
                                            <button ejs-button cssClass="hrep-icon-btn"
                                                    iconCss="icon-cloud-download"></button>
                                        </div>
                                    </div>
                                    <div class="hrep-divider"></div>
                                </ng-template>
                            </ejs-listview>
                        </div>
                    </ng-template>
                </e-accordionitem>
                <e-accordionitem expanded="true" *ngIf="bookmarkEmployees.length">
                    <ng-template #header>
                        <span class="accordion-header-prefix"><i class="icon-doc"></i></span>
                        <div>Merkliste</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="hrep-list hrep-search-list">
                            <ejs-listview
                                    id="listview_template_bookmark_employee"
                                    cssClass="e-list-template"
                                    [dataSource]="bookmarkEmployees"
                            >
                                <ng-template #template let-listItem="">
                                    <div class="e-list-wrapper e-list-multi-line">
                                        <div class="left-section icon-section">
                                            <span>
                                                <img src="../../../../../assets/layout/images/pdf-icon.png" alt=""/>
                                            </span>
                                        </div>
                                        <div class="main-section">
                                            <span class="e-list-item-header hrep-txt-primary">{{listItem.employee.first_name}} {{listItem.employee.last_name}}</span>
                                            <span class="e-list-content">{{listItem.valid_from | date}}</span>
                                        </div>
                                        <div class="right-section">
                                            <button ejs-button cssClass="hrep-icon-btn"><img
                                                    src="../../../../../assets/layout/images/pdf-download-icon.png" alt=""/>
                                            </button>
                                            <button ejs-button cssClass="hrep-icon-btn"
                                                    iconCss="icon-cloud-download"></button>
                                        </div>
                                    </div>
                                    <div class="hrep-divider"></div>
                                </ng-template>
                            </ejs-listview>
                        </div>
                    </ng-template>
                </e-accordionitem>
                <e-accordionitem expanded="true" *ngIf="frequentlyAskedQuestions.length">
                    <ng-template #header>
                        <span class="accordion-header-prefix"><i class="icon-doc"></i></span>
                        <div>FAQs</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="hrep-accordion accordion-primary hrep-sub-accordion">
                        <ejs-accordion expandMode='Single'>
                            <e-accordionitems>
                                <ng-container *ngFor="let question of frequentlyAskedQuestions; let i =index;">
                                    <e-accordionitem>
                                        <ng-template #header>
                                            <span class="accordion-header-prefix">{{i + 1}}</span>
                                            <div>{{question.title}}</div>
                                        </ng-template>
                                        <ng-template #content>
                                            <div>{{question.short_content}}</div>
                                        </ng-template>
                                    </e-accordionitem>
                                </ng-container>
                            </e-accordionitems>
                        </ejs-accordion>
                        </div>
                    </ng-template>
                </e-accordionitem>
                <e-accordionitem expanded="true" *ngIf="messages.length">
                    <ng-template #header>
                        <span class="accordion-header-prefix"><i class="icon-doc"></i></span>
                        <div>Mitteilungen</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="hrep-list hrep-messages-list">
                            <ejs-listview
                                    id="listview_template_messages"
                                    cssClass="e-list-template"
                                    [dataSource]="messages"
                            >
                              <ng-template #template let-listItem="">
                                <div class="e-list-wrapper e-list-multi-line">
                                  <app-message-item [listItem]="listItem" (click)="onOpenMessage(listItem.id)" [isExpanded]="expendedMessage === listItem.id"></app-message-item>
                                </div>
                                <div class="hrep-divider"></div>
                              </ng-template>
                            </ejs-listview>
                        </div>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>
</div>
