<div class="row">
    <div class="col-1 list-left-section pe-0">
        <span *ngIf="listItem.created_at !== ''">{{listItem.date | date}}</span>
    </div>
    <div class="col-9 list-main-section">
        <span class="e-list-item-header hrep-txt-primary">{{listItem.name}}</span>
        <span [ngClass]="{'truncated':!_isExpanded}" class="e-list-content e-text-overflow pt-2"
              [innerHTML]="listItem.text"></span>
        <div *ngIf="listItem.file && _isExpanded" class="hrep-message-attachments">
            <button ejs-button class="hrep-icon-btn p-0 me-2" (click)="downloadAttachment($event)">
                <img class="img-fluid" src="../../../../../../assets/layout/images/pdf-icon.png" alt=""/>
            </button>
        </div>
    </div>
    <div class="col-2 text-end list-right-section">
        <div class="d-inline-block pe-3">
            <button ejs-button cssClass="hrep-icon-btn" *ngIf="listItem.file" (click)="downloadAttachment($event)"
                    iconCss="icon-paper-clip" title="Attachment"></button>
        </div>
        <span *ngIf="!listItem?.is_read"
              class="e-badge e-badge-dot e-badge-circle e-badge-success"></span>
        <button *ngIf="listItem?.is_read" ejs-button cssClass="hrep-icon-btn"
                iconCss="e-icons e-icon-tick"></button>
    </div>
</div>
