import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {User} from '../../../../core/models/user';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CrudService} from '../../../../core/services/crud.service';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import {ToastService} from '../../../../core/services/toast.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit{
// dummy data
  public form: FormGroup;
  public years: number[] = Array.from(Array(100).keys()).map(v => 1920 + v);
  public days: number[] = Array.from(Array(31).keys()).map(v => 1 + v);
  public months: number[] = Array.from(Array(12).keys()).map(v => 1 + v);
  public current_user: User|null = null;
  public isCorrect: boolean = true;
  public formHaveError: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private crud: CrudService,
    private toastService: ToastService
  ) {
    this.form = this.fb.group({
      first_name: this.fb.control('', [FormValidators.required]),
      last_name: this.fb.control('', [FormValidators.required]),
      sex: this.fb.control(''),
      street: this.fb.control('', [FormValidators.required]),
      house_number: this.fb.control('', [FormValidators.required]),
      door_number: this.fb.control(''),
      postal_code: this.fb.control('', [FormValidators.required]),
      town: this.fb.control('', [FormValidators.required]),
      phone_number: this.fb.control('', [FormValidators.required]),
      email: this.fb.control('', [FormValidators.email]),
      birth_date_day: this.fb.control(''),
      birth_date_month: this.fb.control(''),
      birth_date_year: this.fb.control(''),
      new_password: this.fb.control(''),
      new_password_confirmation: this.fb.control(''),
    });
  }

  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      this.current_user = user;
      this.hydrateForm();
    });
  }

  public save(): void {
    this.isCorrect = true;
    if (this.current_user && this.form.valid) {
      this.formHaveError = false;
      const birthDate: Date = new Date();
      birthDate.setFullYear(this.form.get('birth_date_year')?.value, this.form.get('birth_date_month')?.value - 1, this.form.get('birth_date_day')?.value);
      const user: User = {
        id: this.current_user.id,
        first_name: this.form.get('first_name')?.value,
        last_name: this.form.get('last_name')?.value,
        street: this.form.get('street')?.value,
        sex: this.form.get('sex')?.value,
        house_number: this.form.get('house_number')?.value,
        door_number: this.form.get('door_number')?.value,
        postal_code: this.form.get('postal_code')?.value,
        town: this.form.get('town')?.value,
        phone_number: this.form.get('phone_number')?.value,
        email: this.form.get('email')?.value,
        birth_date: birthDate.toUTCString(),
      };

      this.crud.updateEntity<User>('user', this.current_user.id, user).subscribe(aUser => {
        this.authService.getLoggedUser().subscribe(user => {
          this.current_user = user;
          this.hydrateForm();
          this.toastService.show({
            title: 'Success',
            content: 'Profile Saved',
          });
        });
      });
    } else {
      this.formHaveError = true;
    }

    const password = this.form.get('new_password')?.value;
    const passwordConfirmation = this.form.get('new_password_confirmation')?.value;

    if (password && passwordConfirmation && password === passwordConfirmation) {
      this.authService.changePassword({user_id: this.current_user?.id, password}).subscribe();
    } else if (password){
      this.isCorrect = false;
    }
  }

  private hydrateForm(): void {
    if (this.current_user && this.current_user.id) {
      this.form.get('first_name')?.patchValue(this.current_user.first_name);
      this.form.get('last_name')?.patchValue(this.current_user.last_name);
      this.form.get('street')?.patchValue(this.current_user.street);
      this.form.get('sex')?.patchValue(this.current_user.sex);
      this.form.get('house_number')?.patchValue(this.current_user.house_number);
      this.form.get('door_number')?.patchValue(this.current_user.door_number);
      this.form.get('postal_code')?.patchValue(this.current_user.postal_code);
      this.form.get('town')?.patchValue(this.current_user.town);
      this.form.get('phone_number')?.patchValue(this.current_user.phone_number);
      this.form.get('email')?.patchValue(this.current_user.email);
      this.form.get('birth_date_day')?.patchValue(this.current_user.birth_date ? new Date(this.current_user.birth_date).getDate() : null);
      this.form.get('birth_date_month')?.patchValue(this.current_user.birth_date ? new Date(this.current_user.birth_date).getMonth() + 1 : null);
      this.form.get('birth_date_year')?.patchValue(this.current_user.birth_date ? new Date(this.current_user.birth_date).getFullYear() : null);
    }

    this.cd.detectChanges();
  }
}
