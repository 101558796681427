import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    EventEmitter,
    Output,
    ChangeDetectionStrategy
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CrudService} from '../../../../../core/services/crud.service';
import {Customer} from '../../../../../core/models/customer';
import {FormValidators} from '@syncfusion/ej2-angular-inputs';

@Component({
    selector: 'app-contact-person-modal',
    templateUrl: './contact-person-modal.component.html',
    styleUrls: ['./contact-person-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPersonModalComponent implements OnInit {
    @Input() customer: Customer | null = null;
    @Output() public contactPersonSent = new EventEmitter<boolean>();
    public form: FormGroup;
    public years: number[] = Array.from(Array(100).keys()).map(v => 1920 + v);
    public days: number[] = Array.from(Array(31).keys()).map(v => 1 + v);
    public months: number[] = Array.from(Array(12).keys()).map(v => 1 + v);
    public submitInProgress = false;
    public fields: any = {text: 'name', value: 'name'};
    public statuses: { [key: string]: any }[] = [
        {name: 'active'},
        {name: 'inactive'}
    ];
    constructor(
        private crudService: CrudService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService
    ) {
        this.form = this.fb.group({
            birth_date_day: this.fb.control(''),
            birth_date_month: this.fb.control(''),
            birth_date_year: this.fb.control(''),
            street: this.fb.control('', [FormValidators.required]),
            house_number: this.fb.control('', [FormValidators.required]),
            door_number: this.fb.control(''),
            postal_code: this.fb.control('', [FormValidators.required]),
            town: this.fb.control('', [FormValidators.required]),
            email: this.fb.control('', [Validators.required]),
            status: this.fb.control(''),
            first_name: this.fb.control('', [Validators.required]),
            last_name: this.fb.control('', [Validators.required]),
            sex: this.fb.control(''),
            phone_number: this.fb.control(''),
            position: this.fb.control(''),
        });
    }

    ngOnInit(): void {
    }

    public doSubmit(): void {
        const formValues = this.form.value;
        if (this.form.invalid) {
            return;
        }
        const birthDate: Date = new Date();
        birthDate.setFullYear(this.form.get('birth_date_year')?.value, this.form.get('birth_date_month')?.value - 1, this.form.get('birth_date_day')?.value);
        this.submitInProgress = true;
        this.crudService.createEntity('user', {
            birth_date: birthDate.toUTCString(),
            email: formValues.email,
            first_name: formValues.first_name,
            last_name: formValues.last_name,
            status: formValues.status,
            sex: formValues.sex,
            phone_number: formValues.phone_number,
            position: formValues.position,
            house_number: formValues.house_number,
            door_number: formValues.door_number,
            postal_code: formValues.postal_code,
            town: formValues.town,
            customer: this.customer
        })
        .pipe(
            catchError((response: HttpErrorResponse) => {
                this.onCostCenterError(response);
                this.submitInProgress = false;
                return throwError(response);
            }))
        .subscribe((response: any) => {
            this.onCostCenterSuccess(response);
            this.submitInProgress = false;
        });
    }

    private onCostCenterError(response: HttpErrorResponse): void {
        this.form.setErrors({
            loginError: response.error['hydra:description']
        });
        this.cdr.detectChanges();
    }

    private onCostCenterSuccess(response: any): void {
        this.contactPersonSent.emit(true);
    }
}
