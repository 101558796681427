import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {Newsletter} from '../../../../core/models/newsletter';
import {NewsletterService} from '../../../../core/services/newsletter.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterComponent implements OnInit {

  public unreadMessages: Newsletter[] = [];
  public readMessages: Newsletter[] = [];
  public expendedNewsletter: string|null = null;

  constructor(
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private newsletterService: NewsletterService
    ) {
    this.newsletterService.expendedNewsletterChanged$.subscribe((selection) => {
      this.doOpenMessage(selection.message_id);
    });
    this.expendedNewsletter = this.newsletterService.getExpendedNewsletter();
  }

  ngOnInit(): void {
    this.crudService.fetchEntities<Newsletter>('newsletter', this.buildParams()).subscribe(response => {
        this.unreadMessages = response.data;
        this.cd.detectChanges();
      }
    );
  }

  public buildParams(): { [key: string]: string } {
    return {
      include: 'file',
      'sort': '-date',
    };
  }

  public onOpenMessage(message_id: string) {
    this.newsletterService.expandNewsletter(message_id);
  }

  public doOpenMessage(message_id: string) {
    const previousMessageIndex = this.unreadMessages.findIndex((aMessage) => {
      return aMessage.id === this.expendedNewsletter;
    });
    if (previousMessageIndex !== -1) {
      const previousMessage = this.unreadMessages.splice(previousMessageIndex, 1)[0];
      this.unreadMessages = [...this.unreadMessages];
      this.readMessages.splice(0,0, previousMessage);
      this.readMessages = [...this.readMessages];
    }
    this.expendedNewsletter = message_id;
    this.cd.detectChanges();
  }

  public downloadAttachment(event: Event, item: any) {
    event.stopPropagation();
    const a = document.createElement("a"); //Create <a>
    a.href = item.file.file_url; //Image Base64 Goes here
    a.download = item.file.file_name; //File name Here
    a.target = '_blank';
    a.click();
  }
}
