import {ChangeDetectorRef, Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CrudService} from '../../../../../core/services/crud.service';
import {Customer} from '../../../../../core/models/customer';

@Component({
  selector: 'app-working-model-modal',
  templateUrl: './working-model-modal.component.html',
  styleUrls: ['./working-model-modal.component.scss']
})
export class WorkingModelModalComponent implements OnInit {
    @Input() customer: Customer | null = null;
    @Output() public workingModelSent = new EventEmitter<boolean>();
    public form: FormGroup;
    public submitInProgress = false;
    public fields: any = {text: 'name', value: 'name'};
    public statuses: { [key: string]: any }[] = [
        {name: 'active'},
        {name: 'inactive'}
    ];
    constructor(
        private crudService: CrudService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService
    ) {
        this.form = this.fb.group({
            valid_from: this.fb.control('', [Validators.required]),
            valid_to: this.fb.control(''),
            name: this.fb.control('', [Validators.required]),
            status: this.fb.control('', [Validators.required]),
        });
    }

    ngOnInit(): void {
    }

    public doSubmit(): void {
    const formValues = this.form.value;
    console.log(this.form.invalid);
    this.submitInProgress = true;
    this.crudService.createEntity('working_model', {
        name: formValues.name,
        valid_from: formValues.valid_from.toString().slice(0, 24),
        valid_to: formValues.valid_to.toString().slice(0, 24),
        status: formValues.status,
        customer: this.customer
    })
        .pipe(
            catchError((response: HttpErrorResponse) => {
                this.onWorkingModelError(response);

                return throwError(response);
            }))
        .subscribe((response: any) => {
            this.onWorkingModelSuccess(response);
        });
    }

    private onWorkingModelError(response: HttpErrorResponse): void {
        this.form.setErrors({
            loginError: response.error['hydra:description']
        });
        this.cdr.detectChanges();
    }

    private onWorkingModelSuccess(response: any): void {
        this.workingModelSent.emit(true);
    }
}
