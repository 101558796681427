import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  links: any[] = [
    {
      text: 'home',
      url: 'https://itknowation.de/'
    },
    {
      text: 'datenschutz',
      url: '#'
    },
    {
      text: 'impressum',
      url: '#'
    },
  ];

}
