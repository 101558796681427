<div class='content'>
    <div class="container">
        <div class="row">
            <div>
                <form [formGroup]="form" (ngSubmit)="doSubmit()" class="form hrep-vacation-form">
                    <div class="col-12 d-flex align-items-center mb-3">
                        <label>{{ 'CUSTOMER.DESCRIPTION' | translate }}</label>
                        <div class='textboxes'>
                            <ejs-textbox id="name" formControlName="name" cssClass="hrep-input"></ejs-textbox>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center mb-3">
                        <label>{{ 'COMMON.VALID_FROM' | translate }}</label>
                        <div class="form-group">
                            <ejs-datepicker id="startDate" formControlName="valid_from" strictMode='true' format='dd.MM.yyyy' openOnFocus="true" required cssClass="hrep-input">
                            </ejs-datepicker>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center mb-3">
                        <label>{{ 'COMMON.VALID_TO' | translate }}</label>
                        <ejs-datepicker id="endDate" formControlName="valid_to" strictMode='true' format='dd.MM.yyyy' openOnFocus="true" cssClass="hrep-input">
                        </ejs-datepicker>
                    </div>
                    <div class="col-12 d-flex align-items-center mb-3">
                        <label>{{ 'COMMON.STATUS' | translate }}</label>
                        <ejs-dropdownlist id='status' formControlName="status" class="hrep-dropdown suffix-filled" [dataSource]='statuses' [fields]='fields' cssClass="hrep-dropdown no-margin-dropdown"
                        ></ejs-dropdownlist>
                    </div>
                    <div class="form-field text-center">
                        <button [disabled]="submitInProgress" cssClass="e-small" ejs-button type="submit" class="e-btn e-primary">{{ 'COMMON.SAVE' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

