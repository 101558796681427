<div class='content'>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="hrep-accordion accordion-light text-start">
                    <ejs-accordion>
                        <e-accordionitems>
                            <e-accordionitem expanded='true'>
                                <ng-template #header>
                                    <div>{{ employeeData?.employee?.first_name }} {{ employeeData?.employee?.last_name }}</div>
                                </ng-template>
                                <ng-template #content>
                                    <div class="row pb-2">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="row">
                                                    <div class="col-4 d-flex mb-3">
                                                        <label class="col-4">{{ 'CUSTOMER.PERS_NUM' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.number"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label class="col-4">{{ 'CUSTOMER.GENDER' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.gender === 'w' ? 'Frau' : 'Herr'"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label class="col-4">{{ 'CUSTOMER.FIRST_NAME' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.first_name"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label class="col-4">{{ 'CUSTOMER.LAST_NAME' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.last_name"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label class="col-4">{{ 'PROFILE.BIRTH_YEAR' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.birthday | date:'yyyy'"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label class="col-4">{{ 'PROFILE.NATIONALITY' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.nationality"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <button ejs-button iconCss="icon-user" [isPrimary]="true" class="mb-2" content="zum Profile"></button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label class="col-4">{{ 'CUSTOMER.PROFESSION' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.position"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label class="col-4">Kenntnisse</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.skill"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <button ejs-button iconCss="icon-check" [isPrimary]="true" class="mb-2" content="MERKLISTE" (click)="bookmark()" *ngIf="!employeeData?.bookmarked"></button>
                                                        <button ejs-button iconCss="icon-close" [isPrimary]="true" class="mb-2" content="UN MERKLISTE" (click)="unBookmark()" *ngIf="employeeData?.bookmarked"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </e-accordionitem>

                        </e-accordionitems>

                    </ejs-accordion>
                </div>

            </div>
        </div>
    </div>
</div>

