<div class="e-card">
    <div class="e-card-header">
        <div class="e-avatar e-avatar-xlarge e-avatar-circle">
            <img src="{{current_user?.image}}"/>
        </div>
        <div class="e-card-header-caption">
            <div class="e-card-header-title">{{current_user?.first_name}} {{current_user?.last_name}}</div>
            <div class="e-card-sub-title">{{ 'COMMON.STATUS' | translate }}: <strong>{{current_user?.status}}</strong></div>
            <div class="hrep-txt-primary small pt-2">{{current_user?.postal_code}} {{current_user?.town}}</div>
        </div>
    </div>
</div>
<form [formGroup]="form">
    <div class="hrep-accordion accordion-light">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>{{ 'PROFILE.PERSONAL_DATA' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="row">
                            <div class="col-6 d-flex justify-content-between align-items-center mb-2">
                                <label for="name">{{ 'PROFILE.FIRST_NAME' | translate }}</label>
                                <ejs-textbox id="name" [cssClass]="(form.get('first_name').errors ? 'e-error' : '') + ' hrep-input'" type="text" formControlName="first_name"></ejs-textbox>
                            </div>
                            <div class="col-6 d-flex justify-content-between align-items-center mb-2">
                                <label for="surname">{{ 'PROFILE.LAST_NAME' | translate }}</label>
                                <ejs-textbox id="surname" [cssClass]="(form.get('last_name').errors ? 'e-error' : '') + ' hrep-input'" type="text" formControlName="last_name"></ejs-textbox>
                            </div>
                            <div class="col-8 d-flex justify-content-between align-items-center mb-2">
                                <label>{{ 'PROFILE.GENDER' | translate }}</label>
                                <ejs-radiobutton label="{{ 'PROFILE.FEMALE' | translate }}" formControlName="sex" value="w"></ejs-radiobutton>
                                <ejs-radiobutton label="{{ 'PROFILE.MALE' | translate }}" formControlName="sex" value="m"></ejs-radiobutton>
                                <ejs-radiobutton label="{{ 'PROFILE.OTHER' | translate }}" formControlName="sex" value=""></ejs-radiobutton>
                            </div>
                            <div class="col-10 d-flex justify-content-between align-items-center mb-2">
                                <label>{{ 'PROFILE.BIRTHDAY' | translate }}:</label>
                                <div class="col-2">
                                    <ejs-dropdownlist id='dday' [dataSource]='days' cssClass="hrep-dropdown"
                                                      formControlName="birth_date_day"></ejs-dropdownlist>
                                </div>
                                <div class="col-2">
                                    <ejs-dropdownlist id='dmonth' [dataSource]='months' cssClass="hrep-dropdown"
                                                      formControlName="birth_date_month"></ejs-dropdownlist>
                                </div>
                                <div class="col-3">
                                    <ejs-dropdownlist id='dyear' [dataSource]='years' cssClass="hrep-dropdown"
                                                      formControlName="birth_date_year"></ejs-dropdownlist>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-accordionitem>

                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>{{ 'PROFILE.ADDRESS' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="row">
                            <div class="col-5 d-flex justify-content-between align-items-center mb-2">
                                <label>{{ 'PROFILE.STREET' | translate }}</label>
                                <div class='textboxes'>
                                    <ejs-textbox placeholder="{{ 'PROFILE.STREET' | translate }}" [cssClass]="(form.get('street').errors ? 'e-error' : '') + ' hrep-input'" formControlName="street"></ejs-textbox>
                                </div>
                            </div>
                            <div class="col-4 d-flex justify-content-between align-items-center mb-2">
                                <label>{{ 'PROFILE.HOUSE_NO' | translate }}</label>
                                <ejs-textbox
                                        width="50"
                                        [cssClass]="(form.get('house_number').errors ? 'e-error' : '') + ' hrep-input'"
                                        formControlName="house_number"
                                >
                                </ejs-textbox>
                            </div>
                            <div class="col-2 d-flex justify-content-between align-items-center mb-2">
                                <label>{{ 'PROFILE.DOOR_NUMBER' | translate }}</label>
                                <div class='textboxes'>
                                    <ejs-textbox cssClass="hrep-input" width="50" formControlName="door_number"></ejs-textbox>
                                </div>
                            </div>
                            <div class="col-5 d-flex align-items-center justify-content-between mb-2">
                                <label>{{ 'PROFILE.POSTAL_CODE' | translate }}</label>
                                <ejs-textbox
                                    [cssClass]="(form.get('postal_code').errors ? 'e-error' : '') + ' hrep-input'"
                                    formControlName="postal_code"
                                >
                                </ejs-textbox>
                            </div>
                            <div class="col-5 d-flex align-items-center justify-content-between mb-2">
                                <label>{{ 'PROFILE.TOWN' | translate }}</label>
                                <ejs-textbox id="town" [cssClass]="(form.get('town').errors ? 'e-error' : '') + ' hrep-input'" type="text" formControlName="town"></ejs-textbox>
                            </div>
                        </div>
                    </ng-template>
                </e-accordionitem>

                <e-accordionitem expanded="true">
                    <ng-template #header>
                        <div>{{ 'PROFILE.CONTACT_DETAILS' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="col-6 d-flex align-items-center justify-content-between mb-2">
                            <label>{{ 'PROFILE.MOBILE' | translate }}</label>
                            <div class='textboxes'>
                                <ejs-textbox type="tel" [cssClass]="(form.get('phone_number').errors ? 'e-error' : '') + ' hrep-input'" formControlName="phone_number"></ejs-textbox>
                            </div>
                            <span class="icon-check hrep-txt-primary"></span>
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-between mb-2">
                            <label>{{ 'COMMON.EMAIL' | translate }}</label>
                            <div class='textboxes'>
                                <ejs-textbox type="email" [cssClass]="(form.get('email').errors ? 'e-error' : '') + ' hrep-input'" formControlName="email"></ejs-textbox>
                            </div>
                            <span class="icon-check hrep-txt-primary"></span>
                        </div>
                    </ng-template>
                </e-accordionitem>

                <e-accordionitem expanded="true">
                    <ng-template #header>
                        <div>{{ 'PROFILE.SECURITY' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <div class="row">

                        <div class="col-8 d-flex align-items-center justify-content-between mb-2">
                            <label>{{ 'PROFILE.NEW_PASSWORD' | translate }}:</label>
                            <div class='textboxes'>
                                <ejs-textbox placeholder="Password" type="password" cssClass="hrep-input" formControlName="new_password"></ejs-textbox>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                            <div class="col-8 d-flex align-items-center justify-content-between mb-2">
                                <label>{{ 'PROFILE.REPEAT_PASSWORD' | translate }}:</label>
                                <div class="textboxes">
                                    <ejs-textbox placeholder="Password" type="password" [cssClass]="(!isCorrect ? 'e-error' : '') + ' hrep-input'"
                                                 formControlName="new_password_confirmation"></ejs-textbox>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-accordionitem>

            </e-accordionitems>
        </ejs-accordion>

      <div>
        <button ejs-button cssClass="e-primary" class="mt-2 d-flex float-end" (click)="save()">{{ 'COMMON.SAVE' | translate }}</button>
        <div class="hrep-error-msg mt-3 me-2 float-end d-flex" *ngIf="formHaveError"><span class="icon-close"></span><span>You must fill all required fields!</span></div>
      </div>
    </div>
</form>





