import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {CostCenter} from '../../../../core/models/cost-center';
import {WorkingModel} from '../../../../core/models/working-model';
import {Profession} from '../../../../core/models/profession';
import {User} from '../../../../core/models/user';
import {EmitType} from '@syncfusion/ej2-base';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Customer} from '../../../../core/models/customer';

@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationComponent implements OnInit {
    @ViewChild('ejDialog') ejDialog: DialogComponent | undefined;
    public costCenters: CostCenter[] = [];
    public workingModels: WorkingModel[] = [];
    public employees: User[] = [];
    public professions: Profession[] = [];
    public isOpened = false;
    public modalType = '';
    public customer: Customer|undefined;
    public targetElement: HTMLElement | undefined;
    public fields: any = {text: 'name', value: 'name'};
    public statuses: { [key: string]: any }[] = [
        {name: 'active'},
        {name: 'inactive'}
    ];

    constructor(
        private crudService: CrudService,
        private cd: ChangeDetectorRef,
        private authService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.authService.getLoggedUser().subscribe(user => {
            this.customer = user.customer;
            this.crudService.fetchEntities<CostCenter>('cost_center', {'filter[customer_id]': user.customer?.id || 'x'})
                .subscribe(response => {
                        this.costCenters = response.data;
                        this.cd.detectChanges();
                    }
                );

            this.crudService.fetchEntities<WorkingModel>('working_model', {'filter[customer_id]': user.customer?.id || 'x'})
                .subscribe(response => {
                        this.workingModels = response.data;
                        this.cd.detectChanges();
                    }
                );

            this.crudService.fetchEntities<User>('user', {'filter[customer_id]': user.customer?.id || 'x'}).subscribe(response => {
                    this.employees = response.data;
                    this.cd.detectChanges();
                }
            );

            this.crudService.fetchEntities<Profession>('profession', {'filter[customer_id]': user.customer?.id || 'x'})
                .subscribe(response => {
                        this.professions = response.data;
                        this.cd.detectChanges();
                    }
                );
        });
        this.initializeTarget();
    }

    public onAddCostCenter(){
        this.isOpened = true;
        this.modalType = 'costCenter';
        this.ejDialog?.show();
    }

    public onAddUser(){
        this.isOpened = true;
        this.modalType = 'contactPerson';
        this.ejDialog?.show();
    }

    public onAddWorkingModel(){
        this.isOpened = true;
        this.modalType = 'workingModel';
        this.ejDialog?.show();
    }

    public onModalClose(value: boolean){
        this.isOpened = value;
        this.ejDialog?.hide();
        this.modalType = '';
        this.ngOnInit();
    }

    public initializeTarget: EmitType<any> = () => {
        this.targetElement = document.body;
    };

    public beforeOpen(event: any) {
        this.isOpened = true;
    }

    public beforeClose(event: any) {
        this.isOpened = false;
    }
}

