import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {ActivatedRoute} from '@angular/router';
import {Document} from '../../../../core/models/document';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsComponent implements OnInit {

  public documents: Document[] = [];
  public documentType: string|undefined;

  constructor(
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    ) {
  }

  ngOnInit(): void {

      this.route.params.subscribe(routeParams => {
        this.documentType = routeParams.documentType;
        this.crudService.fetchEntities<Document>('document', this.buildParams()).subscribe(response => {
            this.documents = response.data;
            this.cd.detectChanges();
          }
        );
      });
  }

  public buildParams(): { [key: string]: string } {
    return {
      include: 'file',
      'filter[customer_aware]': 'scope',
      'filter[document_type_id]': this.documentType === undefined ? '' : this.documentType,
    };
  }

  public downloadAttachment(event: Event, item: any) {
    event.stopPropagation();
    const a = document.createElement("a"); //Create <a>
    a.href = item.file.file_url; //Image Base64 Goes here
    a.download = item.file.file_name; //File name Here
    a.target = '_blank';
    a.click();
  }
}
