<div class="row">
    <div class="col-12 hrep-headline d-flex align-items-center mb-4">
        <i class="icon-doc me-2"></i>
        <h6 class="small fw-bold mb-0">{{ 'CUSTOMER.EMPLOYEE' | translate }}</h6>
    </div>
    <div class="hrep-accordion accordion-light mb-5">
        <div class="hrep-accordion accordion-light mb-5">
            <ejs-accordion>
                <e-accordionitems>
                    <e-accordionitem expanded='true'>
                        <ng-template #header>
                            <div>{{ ('CUSTOMER.' + this.type.toUpperCase() + '_EMPLOYEES') | translate }}</div>
                        </ng-template>
                        <ng-template #content>
                            <ejs-grid class="hrep-grid" [dataSource]='employees' [enableHover]='false' [rowHeight]='30'
                                      gridLines='Both'>
                                <e-columns>
                                    <e-column width='130'>
                                        <ng-template #template let-data class="p-0">
                                            <button (click)="onOpenDialog(data)" ejs-button
                                                    cssClass="e-block e-small hrep-btn-light">Open </button>
                                        </ng-template>
                                    </e-column>
                                    <e-column field='employee.gender' headerText="{{ 'CUSTOMER.GENDER' | translate }}" textAlign='Center'
                                              width='100'></e-column>
                                    <e-column field='employee.first_name' headerText="{{ 'CUSTOMER.FIRST_NAME' | translate }}" textAlign='Center'
                                              width='100'></e-column>
                                    <e-column field='employee.last_name' headerText="{{ 'CUSTOMER.LAST_NAME' | translate }}" textAlign='Center'
                                              width='100'></e-column>
                                    <e-column field='cost_center.name' headerText="{{ 'CUSTOMER.COST_CENTER' | translate }}" textAlign='Center'
                                              width='100'></e-column>
                                </e-columns>
                            </ejs-grid>
                        </ng-template>
                    </e-accordionitem>
                </e-accordionitems>
            </ejs-accordion>
        </div>
    </div>

    <ejs-dialog id='dialog' #ejDialog showCloseIcon='true' [visible]="false" [position]="{Y:100}"
                (beforeOpen)="beforeOpen($event)" (beforeClose)="beforeClose($event)"
                [target]='targetElement' cssClass="hrep-primary-dialog" width='1200px'>
        <ng-template #content>
            <app-employee-details *ngIf="isOpened && (type === 'active' || type === 'former')" [employeeData]="employee"></app-employee-details>
            <app-candidate-employee-details *ngIf="isOpened && (type === 'candidate' || type === 'bookmark')" [employeeData]="employee"></app-candidate-employee-details>
        </ng-template>
        <ng-template #header>
            <div class="d-flex align-items-center justify-content-center position-relative">
                <div class="e-icon-settings dlg-template text-uppercase">{{ 'CUSTOMER.EMPLOYEE_DETAILS' | translate }}</div>
            </div>
        </ng-template>
    </ejs-dialog>

</div>


