<div class="row">
    <div class="col-12 hrep-headline d-flex align-items-center mb-4">
        <i class="icon-doc me-2"></i>
        <h6 class="small fw-bold mb-0">Suchauftrag</h6>
    </div>

    <div class="hrep-accordion accordion-light">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>Suchauftrag</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid #grid class="hrep-grid" [dataSource]='assignmentStatistics' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>
                                <e-column field='inquiry_number' headerText="Anzahl" textAlign='Center' width='100'></e-column>
                                <e-column field='job_print_text' headerText="Beruf" textAlign='Center' width='auto'></e-column>
                                <e-column field='inquiry_position_status' headerText="Status" textAlign='Center' width='auto'></e-column>
                                <e-column field='assignments_new' headerText="neue Bewerber" textAlign='Center' width='auto'></e-column>
                                <e-column field='assignments_interviewed' headerText="eingeladen" textAlign='Center' width='auto'></e-column>
                                <e-column field='assignments_canceled' headerText="abgelehnt" textAlign='Center' width='auto'></e-column>
                                <e-column field='assignments_confirmed' headerText="eingestellt" textAlign='Center' width='auto'></e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>
</div>
