<div class="e-btn-group text-end">
    <a routerLink="{{link.route}}" cssClass="e-small" ejs-button iconCss="icon-home" [isPrimary]="true"></a>
</div>
<div class="hrep-card">
    <div class="d-flex mb-3">
        <div class="card-left-side">
            <div class="e-avatar e-avatar-xlarge">
                <img class="img-fluid" src="{{current_user?.supervisor?.image}}">
            </div>
        </div>
        <div class="card-right-side ps-3">
            <h4 class="hrep-txt-white mb-0">{{current_user?.supervisor?.first_name}} {{current_user?.supervisor?.last_name}}</h4>
            <p class="small mb-1">{{current_user?.supervisor?.postal_code}} {{current_user?.supervisor?.town}}</p>
            <div class="small">
                <span class="icon-phone hrep-txt-dark me-2"></span>
                <a class="text-decoration-none" href="tel: {{current_user?.supervisor?.phone_number}}" target='_blank'>{{current_user?.supervisor?.phone_number}}</a>
            </div>
            <div class="small">
                <span class="icon-screen-smartphone hrep-txt-dark me-2"></span>
                <a class="text-decoration-none" href="tel: {{current_user?.supervisor?.mobile_number}}" target='_blank'>{{current_user?.supervisor?.mobile_number}}</a>
            </div>
            <div class="small">
                <span class="icon-envelope-open hrep-txt-dark me-2"></span>
                <a class="text-decoration-none" href="mail_to: {{current_user?.supervisor?.email}}" target='_blank'>{{current_user?.supervisor?.email}}</a>
            </div>
            <div class="hrep-popover mt-1">
                <div class="popover-arrow"></div>
                <div class="popover-body">
                    <p class="small mb-0">{{current_user?.supervisor?.description}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex mb-3 bg-dark-orange">
        <div class="card-left-side">
            <div class="e-avatar e-avatar-xlarge" *ngIf="current_user?.branch_office?.image">
                <img class="img-fluid" src="{{current_user.branch_office.image}}">
            </div>
            <div class="e-avatar e-avatar-xlarge" *ngIf="!(current_user?.branch_office?.image)">
                <img class="img-fluid" src="{{current_user?.branch_office?.image}}">
            </div>
        </div>
        <div class="card-right-side ps-3 pt-2 pb-2">
            <h5 class="mb-0 hrep-txt-white">{{current_user?.branch_office?.name}}</h5>
            <p class="mb-0 hrep-txt-white small">{{current_user?.branch_office?.address}}</p>
            <p class="hrep-txt-white small">{{current_user?.branch_office?.postal_code}} {{current_user?.branch_office?.town}}</p>
            <div class="small">
                <span class="icon-phone hrep-txt-white me-2"></span>
                <a class="text-decoration-none" href="tel: {{current_user?.branch_office?.phone_number}}" target='_blank'>{{current_user?.branch_office?.phone_number}}</a>
            </div>
            <div class="small">
                <span class="icon-envelope-open hrep-txt-white me-2"></span>
                <a class="text-decoration-none" href="mail_to: {{current_user?.branch_office?.email}}" target='_blank'>{{current_user?.branch_office?.email}}</a>
            </div>
        </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="sendEmail()">
        <div class="e-input-group e-float-icon-left e-corner">
            <div class="e-input-in-wrap">
                <input formControlName="text" class="e-input" type="text"
                       placeholder="{{ 'COMMON.MESSAGE_TO_SUPERVISOR' | translate }}"/>
            </div>
            <span class="e-input-group-icon icon-envelope-letter" (click)="sendEmail()"></span>
        </div>
    </form>
</div>
