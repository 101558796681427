import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {Router} from '@angular/router';
import {CrudService} from '../../../../../core/services/crud.service';
import {AuthenticationService} from '../../../../../core/services/authentication.service';

@Component({
  selector: 'app-newsletter-item',
  templateUrl: './newsletter-item.component.html',
  styleUrls: ['./newsletter-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterItemComponent {
  public isOpened = false;
  public targetElement: HTMLElement | undefined;

  @Input() listItem: any;

  @Input() set isExpanded(isExpanded: boolean) {
    if (!this._isExpanded && isExpanded) {
      this.markAsRead()
    }
    this._isExpanded = isExpanded;
  }
  @Output() public messageOpened = new EventEmitter<number>();
  public _isExpanded= false;


  constructor(
    private router: Router,
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
  ) {
  }

  protected markAsRead(): void {
    if (this.listItem.is_read === 0) {
      this.listItem.is_read = 1;
      this.crudService.updateEntity('newsletter', this.listItem.id, this.listItem).subscribe(message => {
        this.cd.detectChanges();
      })
    }
  }

  public downloadAttachment(event: Event) {
    event.stopPropagation();
    const a = document.createElement("a"); //Create <a>
    a.href = this.listItem.file.file_url; //Image Base64 Goes here
    a.download = this.listItem.file.file_name; //File name Here
    a.target = '_blank';
    console.log(a);
    a.click();
  }
}

