import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {LocalStorageService} from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  private expendedNewsletter: string = '';
  private expendedNewsletterChanged = new Subject<any>();

  public constructor(
      private http: HttpClient,
  ) {
  }

  expendedNewsletterChanged$ = this.expendedNewsletterChanged.asObservable();

  public expandNewsletter(message_id: string) {
    this.expendedNewsletter = message_id
    this.expendedNewsletterChanged.next({
      message_id: message_id
    });
    return this;
  }

  public getExpendedNewsletter(): string
  {
    return this.expendedNewsletter;
  }
}
