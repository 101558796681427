import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import {CrudService} from '../../../../core/services/crud.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {AssignmentStatistic} from '../../../../core/models/assignmentStatistic';

@Component({
  selector: 'app-assignment-statistic',
  templateUrl: './assignment-statistic.component.html',
  styleUrls: ['./assignment-statistic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentStatisticComponent implements OnInit {
  public assignmentStatistics: AssignmentStatistic[] = [];

  public isOpened = false;
  @ViewChild('grid') public grid: GridComponent | undefined;

  constructor(
    private crudService: CrudService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    ) { }

  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      let customer_id = user.customer?.id;
      if (customer_id) {
        this.crudService.fetchEntities<AssignmentStatistic>('assignment_statistic', {'filter[customer_id]': customer_id}).subscribe(response => {
              this.assignmentStatistics = response.data;
              this.cd.detectChanges();
            }
        );
      }
    });
  }

  public onOpenAssignmentStatisticDialog = (data: any): void => {
    console.log(data);
  };

  public beforeOpen(event: any) {
    this.isOpened = true;
  }

  public beforeClose(event: any) {
    this.isOpened = false;
  }
}
